<template>
    <!-- wrapper -->
	<div>
    

		<!-- INFO BAR -->
		<section class="info-bar info-bar-clean">
			<div class="container">

				<div class="row">

					<div class="col-sm-3">
						<i class="et-browser"></i>
						<h3>이벤트, 홍보웹페이지가 필요하십니까?</h3>
						<p>쉽고 빠르게 블럭쌓기 놀이처럼 만들어 보세요!</p>
					</div>

					<div class="col-sm-3">
						<i class="et-layers"></i>
						<h3>포탈형 홈페이지 필요하십니까?</h3>
						<p>홈페이지를 모아모아 포탈 샤플을 만들 수 있어요!</p>
					</div>

					<div class="col-sm-3">
						<i class="et-newspaper"></i>
						<h3>뉴스레터, 랜딩페이지, 쉽고 빠르게 만들 웹페이지가 필요하십니까?</h3>
						<p>웹페이지를 뭉글로 만들어 보세요!</p>
					</div>

					<div class="col-sm-3">
						<i class="et-lock"></i>
						<h3>비밀 웹페이지가 필요하십니까?</h3>
						<p>웹페이지에 비밀번호를 걸어 웹기획서로 사용해 보세요!</p>
					</div>

				</div>

			</div>
		</section>
		<!-- /INFO BAR -->
	
  </div>
    
</template>


<script>


export default {
	components: {

	}
}
</script>


<style>

</style>

