<template>


		<!-- SLIDE  -->
		<li data-transition="fade" data-slotamount="7" data-masterspeed="300"  data-title="Slide 4" data-target="_blank" data-saveperformance="off">

			<img src="/smart_webpage/assets/images/_smarty/1x1.png" data-lazyload="/images/mainhome/slide01-01.png" alt="" data-bgfit="cover" data-bgposition="left top" data-bgrepeat="no-repeat" />

			<div class="tp-caption lfb" 
				data-x="right" data-hoffset="-70" 
				data-y="120" 
				data-speed="2000" 
				data-start="500" 
				data-easing="easeOutExpo" 
				data-elementdelay="0.1" 
				data-endelementdelay="0.1" 
				data-endspeed="300" 
				style="z-index: 2;">
				<img src="/smart_webpage/assets/images/_smarty/1x1.png" alt="" data-lazyload="/images/mainhome/slide01-02.png">
			</div>

			<div class="tp-caption mediumlarge_light_white lfb tp-resizeme" 
				data-x="left" data-hoffset="50"
				data-y="183" 
				data-speed="1000" 
				data-start="1400" 
				data-easing="easeOutExpo" 
				data-splitin="none" 
				data-splitout="none" 
				data-elementdelay="0.1" 
				data-endelementdelay="0.1" 
				data-endspeed="300" 
				style="z-index: 3; max-width: auto; max-height: auto; white-space: nowrap;">+ Wix보다 쉽고 빠르게 <br> &nbsp;&nbsp; 홈페이지 만들기
			</div>

			<div class="tp-caption mediumlarge_light_white lft tp-resizeme" 
				data-x="left" data-hoffset="70"
				data-y="135" 
				data-speed="1000" 
				data-start="1200" 
				data-easing="easeOutExpo" 
				data-splitin="none" 
				data-splitout="none" 
				data-elementdelay="0.1" 
				data-endelementdelay="0.1" 
				data-endspeed="300" 
				style="z-index: 4; max-width: auto; max-height: auto; white-space: nowrap;">- Wix도 어려우신가요?
			</div>

			<div class="tp-caption block_white sfl tp-resizeme" 
				data-x="70" 
				data-y="266" 
				data-speed="750" 
				data-start="1900" 
				data-easing="easeOutExpo" 
				data-splitin="none" 
				data-splitout="none" 
				data-elementdelay="0.1" 
				data-endelementdelay="0.1" 
				data-endspeed="300" 
				style="z-index: 5; max-width: auto; max-height: auto; white-space: nowrap;">고민하지 말고, 
			</div>

			<div class="tp-caption block_theme_color sfr tp-resizeme" 
				data-x="120" 
				data-y="310" 
				data-speed="750" 
				data-start="2200" 
				data-easing="easeOutExpo" 
				data-splitin="none" 
				data-splitout="none" 
				data-elementdelay="0.1" 
				data-endelementdelay="0.1" 
				data-endspeed="300" 
				style="z-index: 6; max-width: auto; max-height: auto; white-space: nowrap;">EasyHB로 만들면 
			</div>

			<div class="tp-caption block_white sfb tp-resizeme" 
				data-x="170" 
				data-y="356" 
				data-speed="750" 
				data-start="2500" 
				data-easing="easeOutExpo" 
				data-splitin="none" 
				data-splitout="none" 
				data-elementdelay="0.1" 
				data-endelementdelay="0.1" 
				data-endspeed="300" 
				style="z-index: 7; max-width: auto; max-height: auto; white-space: nowrap;">당신의 시간적 여유를 찾아 드립니다.
			</div>
		</li>


</template>

<script>
export default {
  


}
</script>