/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import Vue from 'vue'
import axios from "axios"
import Main from '../views/main/main.vue'
import Router from 'vue-router'
import Mainrouter from '../router/main_router'
import { store } from '../store/store.js';
import Common from '../mixins/common';
import Mymodules from '../mixins/main';
import Vuetify from 'vuetify'
import Element from 'element-ui'
import ElementLocale from 'element-ui/lib/locale/lang/ko';
import 'babel-polyfill'
import 'vuetify/dist/vuetify.min.css'

Vue.mixin(Mymodules);
Vue.mixin(Common);
Vue.use(Router);
Vue.use(Vuetify);
Vue.use(Element, { locale: ElementLocale });

let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
var headers = {
    'Content-Type': 'application/json',
    'request-type': 'client',
    'Accept': 'application/json',
    'X-CSRF-Token': token
}

Vue.prototype.$http = axios.create({ headers: headers });
const router = new Router(Mainrouter)

document.addEventListener('DOMContentLoaded', () => {
    const app = new Vue({
        el: '#main',
        router,
        store,
        render: h => h(Main)
    })
})


// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>


// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the using turbolinks, install 'vue-turbolinks':
//
// yarn add 'vue-turbolinks'
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })