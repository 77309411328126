import Mainpage from '../views/main/pages/mainpage.vue'
import Loginpage from '../views/main/pages/loginpage.vue'
import PromotionCheck from '../views/main/pages/promotion_check.vue'
import Joinpage from '../views/main/pages/signuppage.vue'
import PasswordFind from '../views/main/pages/password_find'
import PasswordEdit from '../views/main/pages/password_edit'
import Promotion from '../views/main/pages/promotion'


export default {
    mode: 'history',
    routes: [
        { path: '/', name: 'mainpage', component: Mainpage },
        { path: '/login', name: 'Loginpage', component: Loginpage },
        { path: '/promotion_check', name: 'PromotionCheck', component: PromotionCheck },
        { path: '/members/sign_up', name: 'Signuppage', component: Joinpage },
        { path: '/promotion/:promotion_key', name: 'Promotion', component: Promotion },
        { path: '/password/new', name: 'PasswordNew', component: PasswordFind },
        { path: '/password/edit', name: 'Passwordedit', component: PasswordEdit },
    ]
};