import moment from 'moment'

export default {

    computed: {
        logined() {
            // console.log("logined------------------");
            // console.log(this.$store.state.login);
            return this.$store.state.login;
        },
        totalCount() {
            return this.$store.state.totalCount;
        },
        no_image() {
            return 'https://s3.ap-northeast-2.amazonaws.com/easyhbimages/noimage.png'
        }
    },
    methods: {
        axios() {
            // this.$http.defaults.headers.common["X-CSRF-Token"] = document.getElementsByName('csrf-token')[0].getAttribute('content')
            this.$http.defaults.headers.common["authToken"] = localStorage.token
            this.$http.defaults.headers.common["req-type"] = 'client'
            try {
                if (webpage_id) this.$http.defaults.headers.common["webpage-id"] = webpage_id
            } catch (e) {}
            return this.$http;
        },
        notice(data) {
            if (data.title == undefined) data.title = ''
            if (data.type == undefined) data.type = 'error'
            if (data.loc == undefined) data.loc = '#' //이동할 url
            if (data.loctype == undefined) data.loctype = 'router' //a 타입 or router-link 타입
            if (data.time == undefined) data.time = 2000
            this.$notify({
                title: data.title,
                message: data.msg,
                type: data.type
            });
            if (data.loctype == 'router') {
                if (data.loc != '#') {
                    this.$router.push(data.loc)
                }
            } else {
                if (data.loc != '#') {
                    setTimeout(function() {
                        location.href = data.loc
                    }, data.time)
                }
            }
        },
        authCheck() {
            // this.$store.state.login = logincheck
            this.axios().get('/loginCheck')
                .then(result => {
                    console.log('authcheck================');
                    // console.log(result);
                    
                    this.$store.commit('set_login',result)

                    // if (result.headers.authtoken == '' || result.headers.authtoken == undefined) {
                    //     localStorage.removeItem('token');
                    //     this.$store.state.login = false;
                    //     if (this.$router.name == 'mainpage') location.href = '/'
                    //     console.log('1.auth-logined = ' + this.$store.state.login);
                    // } else {
                    //     localStorage.setItem('token', result.headers.authtoken)
                    //     console.log('2.auth-logined = ' + this.$store.state.login);
                    // }
                })
        },

        pageMove(link, newpage = true, name='') {
            if (newpage) {
                window.open(link, name)
            } else {
                location.href=link
            }
        },

        logout(loc = '/') {
            this.axios().delete('/logout', {})
                .then((result) => {
                    // console.log(result);
                    // localStorage.removeItem('token');
                    this.$store.state.login = false
                    location.href = loc
                })
        },

        loginCheck(to) {
            if (!this.$store.state.login) {
                return this.logout(to)
            } else {
                return false
            }
        },

        modalShow(id) {
            $(id).modal('show');
            $("body").removeAttr("style");
        },

        modalClose(id) {
            $(id).modal('hide');
        },

        object_empty_check(obj){
            if(typeof(obj)=='string'){
                return this.isEmpty(obj)  
            }else if(obj==undefined){
                return true
            }else{
                return Object.keys(obj).length === 0 && obj.constructor === Object
            }
        },

        isEmpty(value) {
            if (value != undefined) value = value.replace(/\s/i, '')
            if (value == "" || value == null || value == undefined || (value != null && typeof value == "object" && !Object.keys(value).length)) {
                return true;
            } else {
                return false;
            }
        },

        listNumber(index) {
            return (this.totalCount - (this.perPage * (this.current_page - 1))) - index;
        },


        itemNumber(index) {
            return (this.totalCount - (this.perPage * (this.currentx - 1))) - index;
        },

        showAllMemo(memo) {
            if(/<iframe*|<\/iframe/.test(memo))return ''
            if (memo) return memo.split('\n').join('<br />');
        },

        dateFormat(date, format = "YYYY-MM-DD") {
            if (date) return moment(new Date(date).getTime()).format(format);
        },

        showMemo(data, limit) {
            var tmpElement = document.createElement("div");
            var limitLength = limit;
            tmpElement.innerHTML = this.strip(unescape(data));
            var text = tmpElement.textContent || tmpElement.innerText || "";
            if (text.length >= limitLength) {
                return text.substr(0, limitLength) + "...";
            }
            if (data != undefined) {
                return text;
            }
        },

        strip(html) {
            var tmp = document.implementation.createHTMLDocument("New").body;
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || "";
        },

        summernote_set(context_color={}) {
            var font_list = this.$store.state.font_list
            var _container_1 = jQuery('textarea.summernote');
            if (_container_1.length > 0) {
                loadScript(plugin_path + 'editor.summernote/summernote.min.js', function() {
                    if (jQuery().summernote) {
                        _container_1.each(function() {
                            var _lang = jQuery(this).attr('data-lang') || 'en-US';
                            if (_lang != 'en-US') {
                                // Language!
                                loadScript(plugin_path + 'editor.summernote/lang/summernote-' + _lang + '.js');
                            }
                            jQuery(this).summernote({
                                height: jQuery(this).attr('data-height') || 200,
                                lang: 'ko-KR',
                                defaultFontName: 'Nanum Gothic',
                                fontNames: font_list,
                                fontSizes: ['8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '25', '30', '36', '40'],
                                toolbar: [ /*	[groupname, 	[button list]]	*/
                                    ['style', ['style']],
                                    ['fontname', ['fontname']],
                                    ['fontsize', ['fontsize']],
                                    ['style', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
                                    ['height', ['height']],
                                    ['color', ['color']],
                                    ['para', ['ul', 'ol', 'paragraph']],
                                    ['table', ['table', 'hr']],
                                    ['misc', ['codeview', 'help']]
                                ],
                                disableDragAndDrop: true
                            });
                            //summernote 초기화 하기 
                            var context_field = jQuery('.note-editable');
                            if (context_field.length > 0) {
                                $('.note-editable').css('font-family', 'Nanum Gothic').css('font-size', 13)
                            }
                        });
                    }
                });
            }
        },

        escape_html(string) {
            let entityMap = {
                '&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
                "'": '&#39;',
                '/': '&#x2F;',
                '`': '&#x60;',
                '=': '&#x3D;'
            };
            return String(string).replace(/[&<>"'`=\/]/g, function(s) {
                return entityMap[s];
            });
        },

        htmlDecode(input) {
            if (this.isEmpty(input)) {
                return ''
            } else {
                var doc = new DOMParser().parseFromString(input, "text/html");
                return doc.documentElement.textContent;
            }
        },

        lightOrDark(color) {
            if(color==undefined)color = '#fff'
            // Variables for red, green, blue values
            var r, g, b, hsp;
            // Check the format of the color, HEX or RGB?
            if (color.match(/^rgb/)) {
                // If HEX --> store the red, green, blue values in separate variables
                color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
                r = color[1];
                g = color[2];
                b = color[3];
            } else {
                // If RGB --> Convert it to HEX: http://gist.github.com/983661
                color = +("0x" + color.slice(1).replace(
                    color.length < 5 && /./g, '$&$&'));
                r = color >> 16;
                g = color >> 8 & 255;
                b = color & 255;
            }
            // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
            hsp = Math.sqrt(
                0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)
            );
            // Using the HSP value, determine whether the color is light or dark 초기값:127.5
            // if (hsp>185) {
            // 		return 'light';
            // } else {
            // 		return 'dark';
            // }
            return hsp;
        },

        page_query(){
            let query = ""
            if(!this.object_empty_check(this.$route.query))query +="?"
            let cnt = 0
            for(let q in this.$route.query){
            if(cnt>0) query += "&"
                query += `${q}=${this.$route.query[q]}`
                cnt++
            }
            return query;
        },

        test(to) {
            console.log(to);
            return '/'
        }

    }
}