<template>


						<!-- SLIDE  -->
						<li data-transition="fade" data-slotamount="7" data-masterspeed="300"  data-saveperformance="off" data-title="Slide 3">
							<img src="/smart_webpage/assets/images/_smarty/1x1.png" data-lazyload="/images/mainhome/slide03-01.png" alt="" data-bgfit="cover" data-bgposition="left top" data-bgrepeat="no-repeat" />

							<div class="tp-caption lfr" 
								data-x="left" data-hoffset="60"
								data-y="85" 
								data-speed="1500" 
								data-start="500" 
								data-easing="easeOutExpo" 
								data-elementdelay="0.1" 
								data-endelementdelay="0.1" 
								data-endspeed="300" 
								style="z-index: 2;">
								<img src="/smart_webpage/assets/images/_smarty/1x1.png" data-lazyload="/images/mainhome/slide03-02.png" alt="" />
							</div>

							<div class="tp-caption block_black sft" 
								data-x="right" data-hoffset="-70"
								data-y="137" 
								data-speed="750" 
								data-start="1100" 
								data-easing="easeOutExpo" 
								data-splitin="none" 
								data-splitout="none" 
								data-elementdelay="0.1" 
								data-endelementdelay="0.1" 
								data-endspeed="300" 
								style="font-size: 2rem;z-index: 3; max-width: auto; max-height: auto; white-space: nowrap;">시간이 되면 나타났다 사라지는 섹션 블럭
							</div>

							<div class="tp-caption block_black sfb" 
								data-x="right" data-hoffset="-70" 
								data-y="176" 
								data-speed="750" 
								data-start="1400" 
								data-easing="easeOutExpo" 
								data-splitin="none" 
								data-splitout="none" 
								data-elementdelay="0.1" 
								data-endelementdelay="0.1" 
								data-endspeed="300" 
								style="z-index: 4; max-width: auto; max-height: auto; white-space: nowrap;">이벤트와 홍보용 웹페이지를 위한 다양한 섹션 블럭
							</div>

							<div class="tp-caption block_theme_color sft" 
								data-x="right"  data-hoffset="-70"
								data-y="239" 
								data-speed="750" 
								data-start="1700" 
								data-easing="easeOutExpo" 
								data-splitin="none" 
								data-splitout="none" 
								data-elementdelay="0.1" 
								data-endelementdelay="0.1" 
								data-endspeed="300" 
								style="z-index: 5; max-width: auto; max-height: auto; white-space: nowrap;">YOUTUBE, VIMEO, SOUNDCLOUD 미디어 섹션 블럭
							</div>

							<div class="tp-caption block_theme_color sfb" 
								data-x="right"  data-hoffset="-70"
								data-y="278" 
								data-speed="750" 
								data-start="2000" 
								data-easing="easeOutExpo" 
								data-splitin="none" 
								data-splitout="none" 
								data-elementdelay="0.1" 
								data-endelementdelay="0.1" 
								data-endspeed="300" 
								style="z-index: 6; max-width: auto; max-height: auto; white-space: nowrap;">20+ 가지 이상의 다양한 섹션 블럭
							</div>

							<div class="tp-caption block_black sft" 
								data-x="right"  data-hoffset="-70"
								data-y="340" 
								data-speed="750" 
								data-start="2300" 
								data-easing="easeOutExpo" 
								data-splitin="none" 
								data-splitout="none" 
								data-elementdelay="0.1" 
								data-endelementdelay="0.1" 
								data-endspeed="300" 
								style="z-index: 7; max-width: auto; max-height: auto; white-space: nowrap;">페이지 이동이 쉬운 링크 섹션 블럭
							</div>

							<div class="tp-caption block_black sft" 
								data-x="right"  data-hoffset="-70"
								data-y="380" 
								data-speed="750" 
								data-start="2300" 
								data-easing="easeOutExpo" 
								data-splitin="none" 
								data-splitout="none" 
								data-elementdelay="0.1" 
								data-endelementdelay="0.1" 
								data-endspeed="300" 
								style="z-index: 7; max-width: auto; max-height: auto; white-space: nowrap;">섹션에 아이템을 데이터로 넣어 관리한다.
							</div>
						</li>

</template>

<script>
export default {
  


}
</script>