<template>
  <div>
    <section class="page-header">
        <div class="container">
            <h1>{{ subbar_data.title }}</h1>
            <ol class="breadcrumb">
                <li><a href="#">{{ subbar_data.breadcrumb[0]}}</a></li>
                <li class="active">{{ subbar_data.breadcrumb[1]}}</li>
            </ol>
        </div>
    </section>
  </div>
</template>
<script>

export default {
  props: ['subbar_data']  
}
</script>
