<template>

    <!-- SLIDE  -->
    <li data-transition="fade" data-slotamount="1" data-masterspeed="1500" data-delay="10000" data-saveperformance="off" data-title="Slide 1" style="background-color: #F6F6F6;">

      <img src="/images/mainhome/slide02-01.png" alt="video" data-bgposition="top center" data-bgfit="cover" data-bgrepeat="no-repeat">

      <div class="tp-caption customin ltl tp-resizeme revo-slider-caps-text uppercase"
        data-x="left" data-hoffset="0"
        data-y="70"
        data-customin="x:-200;y:0;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
        data-speed="400"
        data-start="1000"
        data-easing="easeOutQuad"
        data-splitin="none"
        data-splitout="none"
        data-elementdelay="0.01"
        data-endelementdelay="0.1"
        data-endspeed="1000"
        data-endeasing="Power4.easeIn">
        <img src="/images/mainhome/slide02-02.png" alt="">
      </div>

      <div class="tp-caption large_bold_grey skewfromrightshort customout font-open-sans"
        data-x="645"
        data-y="70"
        data-customout="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0.75;scaleY:0.75;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
        data-speed="500"
        data-start="800"
        data-easing="Back.easeOut"
        data-endspeed="500"
        data-endeasing="Power4.easeIn"
        data-captionhidden="off"
        style="z-index: 4; font-weight:bold;color:white">블럭쌓기 놀이처럼
      </div>

      <div class="tp-caption large_bold_grey skewfromleftshort customout font-open-sans"
        data-x="645"
        data-y="133"
        data-customout="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0.75;scaleY:0.75;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
        data-speed="300"
        data-start="1100"
        data-easing="Back.easeOut"
        data-endspeed="500"
        data-endeasing="Power4.easeIn"
        data-captionhidden="off"
        style="z-index: 7; font-weight:bold !important;color:white">쉽고 빠르게
      </div>

      <div class="tp-caption ltl customin customout small_light_white font-lato"
        data-x="650"
        data-y="235"
        data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1.3;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
        data-speed="500"
        data-start="1300"
        data-easing="easeOutQuad"
        data-splitin="none"
        data-splitout="none"
        data-elementdelay="0.01"
        data-endelementdelay="0.1"
        data-endspeed="500"
        data-endeasing="Power4.easeIn" style=" color: #FCCE37; font-size:20px; max-width: 550px; white-space: normal; text-shadow:none;font-weight:bold">
        섹션블럭을 넣고, 빼고, 감추고..  <br />
        디자인 교체를 즉시 적용한다.<br />
        <strong>직관적인 인터페이스</strong>
      </div>

      
      <div class="tp-caption large_bold_darkblue skewfromleftshort customout"
        data-x="650" data-hoffset="-90"
        data-y="370"
        data-customout="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0.75;scaleY:0.75;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
        data-speed="500"
        data-start="1600"
        data-easing="Back.easeOut"
        data-endspeed="500"
        data-endeasing="Power4.easeIn"
        data-captionhidden="off"
        style="z-index: 10; text-shadow:none;color:#000">다양한 섹션블럭+
      </div>

      <div class="tp-caption medium_bg_darkblue skewfromleftshort customout"
        data-x="650" data-hoffset="-90"
        data-y="430"
        data-customout="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0.75;scaleY:0.75;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
        data-speed="500"
        data-start="1650"
        data-easing="Back.easeOut"
        data-endspeed="500"
        data-endeasing="Power4.easeIn"
        data-captionhidden="off"
        style="z-index: 11; text-shadow:none;">쉬운 순서교체
      </div>

      <div class="tp-caption medium_bold_red skewfromleftshort customout start font300"
        v-if="false"
        data-x="800" data-hoffset="-90"
        data-y="390"
        data-customout="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0.75;scaleY:0.75;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
        data-speed="500"
        data-start="1850"
        data-easing="Back.easeOut"
        data-endspeed="500"
        data-endeasing="Power4.easeIn"
        data-captionhidden="off"
        style="z-index: 13; text-shadow:none; font-weight:300;">+ RTL <strong>Support</strong>
      </div>

      <div class="tp-caption medium_bg_red skewfromleftshort customout"
        data-x="800" data-hoffset="-90"
        data-y="430"
        data-customout="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0.75;scaleY:0.75;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
        data-speed="500"
        data-start="2300"
        data-easing="Back.easeOut"
        data-endspeed="500"
        data-endeasing="Power4.easeIn"
        data-captionhidden="off"
        style="z-index: 21; text-shadow:none;">새로작성 없이 디자인 변경
      </div>

    </li>


</template>

<script>
export default {
  


}
</script>