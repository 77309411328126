<template>
    <!-- wrapper -->
    <div id="wrapper">
        <sub-bar v-bind:subbar_data='subbar_data'></sub-bar>
        <section>
            <div class="container">
                
                <div class="row">

                    <!-- LOGIN -->
                    <div class="col-lg-4 col-md-3" ></div>
                    <div class="col-lg-4 col-md-6" >
                        <form class="nomargin sky-form boxed">
                            <header>
                             <i class="fa fa-users"></i> 프로모션을 통한 회원가입
                            </header>

                            <fieldset class="nomargin">	
                                <div class="alert alert-danger" v-if="error!=''">{{error}}</div> 
                                <div class="row margin-bottom-10">
                                  <div class="col-md-12">
                                    <b>프로모션코드 입력</b>
                                    <label class="input margin-bottom-10">
                                      <i class="ico-append fa fa-key"></i>
                                      <input type="text" ref='userid' placeholder="Promotion Code" v-model="promotion_join_code"  >
                                      <b class="tooltip tooltip-bottom-right">프로모션 코드는 필수입니다.</b>
                                    </label>
                                  

                                  </div>  
                                </div>
                            
                            </fieldset>

                            <div class="row margin-bottom-20 text-right">

                                <div class="col-md-12" style="padding-right: 40px;margin-bottom: 30px">
                                    <div  class="btn btn-primary" @click="promotion_join_check()"><i class="fa fa-check"></i> 확인</div>
                                </div>
                            </div>

                        </form>
                            <div class="card card-default" style="padding: 10px">
                              <div>
                                ssharple 서비스는 현재 프로모션으로만 진행하고 있습니다. 사용문의는 아래의 이메일로 문의해 주시기 바랍니다.
                              </div>
                              <div style="font-weight: bold">
                                문의: ssharplenet@gmail.com
                              </div>
                            </div>
                        <!-- /register form -->

                    </div>
                    <!-- /LOGIN -->

                    

                </div>

                
            </div>
        </section>
        <!-- / -->

    </div>
    
</template>


<script>
import SubBar from "../../common_components/subbar"
import Vue from 'vue'
import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);

export default {
   data(){
       return {
          pre_code: '',
          promotion_join_code: '',
          error: '',
          subbar_data: {
              title: '프로모션 회원가입 확인',
              breadcrumb: ['HOME','Promotion member join']
          }
       }
    },
    mounted(){
      this.$refs.userid.focus()
    },
    
    components: {
        SubBar
    }, 
    methods:{
        
      
      promotion_join_check(){
        console.log(this.$route);
        if(this.isEmpty(this.promotion_join_code))return false
        if(this.pre_code == this.promotion_join_code)return false
        this.pre_code = this.promotion_join_code
        this.$store.dispatch('promotion_code_confirm', this.promotion_join_code)
        .then(result=>{
           if(result.data.result=='success'){
             console.log(result.data);
             this.$router.push(`/members/sign_up?pkey=${result.data.res_data.promotion_key}`)
           }else{
             this.error = result.data.msg
           }
        })  
        const self = this
        setTimeout(function(){
          self.pre_code = ''
        },5000)
      },

    }
  
}
</script>


<style>


</style>

