<template>
  
    <div class="container">
      <div class="row">

        <div class="col-md-12" v-if='menu_mode==1' >
          <h3>회원가입코드</h3>
          <div class="row">

            <div class="col-md-12">

              <div class="box-icon box-icon-center box-icon-round box-icon-transparent box-icon-large box-icon-content">
                <div class="box-icon-title" >
                  <h2>PROMOTION 회원가입 코드 설정</h2>
                  <table class="table " >
                    <tr>
                      <td :key='promotion_code_key'>
                        <label>PROMOTION  회원가입 사용여부</label>
                        <label class="radio">
                          <input type="radio" name="promotion_join" v-model='promotion_join' @click="promotion_join_save(0)" value="0" :checked="promotion_join == 0 ? 'checked' : ''">
                          <i></i> 사용안함
                        </label>
                        <label class="radio">
                          <input type="radio" name="promotion_join" v-model='promotion_join'  @click="promotion_join_save(1)" value="1" :checked="promotion_join == 1 ? 'checked' : ''">
                          <i></i> 사용함
                        </label>
                     
                      </td>
                      <td style="text-align: left">
                        PROMOTION 코드입력<font style="font-size: 9pt;color:red">(영문 또는 한글 3자이상 20자이하)</font>
                        <span v-if="!code_input">
                          <h3 style="margin-bottom: 0px">{{ isEmpty(promotion_join_code) ? '코드없음' : promotion_join_code }}</h3>
                          <span @click="code_input=true" style="cursor:pointer">[수정]</span>
                        </span>

                        <span class="search-box" v-else >
                          <div class="input-group" style="width: 300px;margin-bottom:20px">
                            <input type="text" name="src" v-model="promotion_join_code" placeholder="PROMOTION 코드" class="form-control">
                            <span class="input-group-btn">
                              <button class="btn btn-primary" @click="promotion_join_code_save()" >입력</button>
                            </span>
                          </div>
                          <div @click="code_input=false" style="cursor:pointer">[취소]</div>
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
                
              </div>

            </div>

          </div>

          <table class="table">
            <thead>
              <th>no</th>
              <th>회원가입코드</th>
              <th>flag</th>
            </thead>
            <tbody>
              <tr v-for="(p, i) in promotion_keys" :key="p.id" >
                <td> {{ promotion_keys.length - i }} </td>
                <td> {{ url_generator(p)}}</td>
                <td>{{ p.flag == 1 ? '사용중' : '' }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="col-md-12" v-if="menu_mode==2" >
          <h3>회원사이트</h3>
          <table class="table">
              <thead>
                <th>no</th>
                <th>회원정보</th>
                <th>샤플정보</th>
              </thead>
              <tbody>
                <tr v-for="(m, i) in members" :key="'member_'+ m.id" >
                  <td> {{ members.length - i }} </td>
                  <td> 
                    {{ m.username }}<br>
                    {{ m.email }}<br>
                    {{ m.userphone }}
                  </td>
                  <td>
                    <table class="table">
                      <tbody>
                        <tr v-for="(h,i) in get_homepage(m)" :key="'home_'+i">
                          <td>
                            {{ service_type[h.webpage_type] }}
                          </td>
                          <td>
                            {{ h.title}}<br>
                            <a href="javascript:void(0)" @click="move_home(h)" style="">{{ host }}/{{h.short_url}}</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </div>

</template>

<script>
export default {
  props: ['menu_mode'],

  data(){
    return {
      promotion_code_key: 0,
      promotion_join_code: '',
      promotion_join: 0,
      code_input: false,
      service_type: { 1:'포탈형', 2: '샤플', 3: '뭉글' }
    }

  },

  watch: {
    promotion(val){
      this.promotion_join_code = val.promotion_join_code
      this.promotion_join = val.promotion_join
      return val
    }
  },

  mounted(){
  },
  
  computed: {
    promotion(){
      return this.$store.state.promotion
    },

    promotion_keys(){
      return this.$store.state.promotion_keys
    },

    homepages(){
      return this.$store.state.homepages
    },

    members(){
      return this.$store.state.members
    },

    host(){
      const port = process.env.NODE_ENV == "development" ? ":" + window.location.port : "";
      return window.location.protocol + '//' + window.location.hostname + port;
    }

  },

  methods: {
    url_generator(p){
      return `${this.host}/members/sign_up?pkey=${p.code}`
    },

    get_homepage(m){
      return this.homepages.filter(p=>p.member_id==m.id)
    },

    move_home(h){
      const link=`${this.host}/${h.short_url}`
      window.open(link, h.name)
    },
    promotion_join_click(val){
      if(!this.isEmpty(this.promotion_join_code) && this.promotion_join_code.length > 2 ){
        this.promotion_join = val
        return true
      }else{
        this.promotion_join = 0
        this.$notify({
          title: '에러',
          message: 'promotion코드가 없거나 코드가 3자 이상이어야 합니다.',
          type: 'error'
        });
        this.promotion_code_key += 1 
        return false
      }
    },

    promotion_join_code_save(){
      const check = this.promotion_join_click(this.promotion_join)
      if(check==false)return false
      console.log(this.promotion_join_code==this.promotion.promotion_join_code);
      if(this.promotion_join_code==this.promotion.promotion_join_code)return false
      const data = { data: {type: 1, promotion_join_code : this.promotion_join_code}, key: this.$route.params.promotion_key  }
      this.$store.dispatch('update_promotion_join_code', data)
      .then(result=>{
        if(result.data.result=='success'){
          this.code_input = false
          this.$notify({
            title: '완료',
            message: '성공적으로 업데이트 되었습니다.',
            type: 'success'
          });
        }else{
          this.promotion_join_code=this.promotion.promotion_join_code
        }
      })
    },

    promotion_join_save(type){
      const check = this.promotion_join_click(type)
      if(check==false)return false
      if(type==this.promotion.promotion_join)return false
      const data = { data: {type: 2, promotion_join: type}, key: this.$route.params.promotion_key  }
      this.$store.dispatch('update_promotion_join_code', data)
      .then(result=>{
        if(result.data.result=='success'){
          this.code_input = false
          this.$notify({
            title: '완료',
            message: '성공적으로 업데이트 되었습니다.',
            type: 'success'
          });
        }else{
          this.promotion_join=this.promotion.promotion_join
        }
      })
    }

  }

}
</script>

<style scoped>
  a{
    color:brown
  }
  a:hover{
    color:blue
  }


</style>