<template>

    <div class="modal-body modal-short" style="margin-left:20px">
        <h4><b>개인정보 수집 및 이용</b></h4>
        <p class="p1">수집하는 개인정보 항목</p>
        <p class="p1">회사는 회원가입 및 서비스 신청, 고객상담 등을 위해 아래와 같은 개인정보를 수집하고 있습니다.</p> <br>
        <p class="p1"><b>1. 회원가입 시</b></p>
        <p class="p2"><일반회원(만 14세 이상 개인)></p>
        <p class="p2">이름, 신청아이디, 비밀번호, 이메일, 휴대폰번호,  본인확인값(CI, DI)</p>
        <p class="p2"><개인사업자></p>
        <p class="p2">상호, 사업자등록번호, 대표자 인증(이름, 성별), 사업장소재지, 신청아이디, 비밀번호, 이메일, 휴대폰번호,  본인확인값(CI, DI)</p>
        <p class="p2"><법인사업자></p>
        <p class="p2">법인명, 법인등록번호, 사업자등록번호, 대표자 인증(이름, 성별), 사업장소재지, 신청아이디, 비밀번호, 이메일, 휴대폰번호,  본인확인값(CI, DI)</p>
        <p class="p2"><어린이/청소년 회원(만 14세 미만 개인)></p>
        <p class="p2">이름, 법정대리인 인증(이름, 성별), 신청아이디, 비밀번호, 이메일, 휴대폰번호,  본인확인값(CI, DI)</p>
        <p class="p2"><외국인회원(국내외 거주 외국인)></p>
        <p class="p2">이름, 여권번호, 외국인등록번호, 신청아이디, 비밀번호, 이메일, 휴대폰번호,  본인확인값(CI, DI)</p><br>

        <p class="p1"><b>2. 일부 서비스 신청 시</b></p>
        <p class="p2"><사업자 관련 정보></p>
        <p class="p2">상호, 사업자등록번호, 대표자 이름, 사업장주소 / 전화번호 / 팩스번호 / 이메일주소, 홈페이지 주소, 통신판매업 신고번호, 과세상태, 쇼핑몰명, 쇼핑몰 URL, 업태, 종목</p>
        <p class="p2"><관리자 정보></p>
        <p class="p2">관리자 이름, 관리자 이메일, 관리자 휴대전화번호, 관리자 유선전화번호</p>
        <p class="p2"><광고담당자 및 광고 관련 정보></p>
        <p class="p2">광고담당자명, 광고담당자 이메일, 광고담당자 휴대전화번호, 광고담당자  광고 관련 정보(가입상태/유형, 사용자 희망 ID/이메일, 비밀번호, 주력상품키워드, 입금은행)</p><br>
        
        <p class="p1"><b>3. 환불 시</b></p>
        <p class="p3">계좌정보(거래은행명, 계좌번호, 거래자 성명)</p>
        <p class="p2">서비스 이용과정이나 사업 처리과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.</p>
        <p class="p2">서비스 이용기록, 접속로그, 쿠키, 접속IP 정보, 결제기록, 이용정지기록, 불량이용기록</p><br>
       
        <p class="p3">개인정보 수집 및 이용목적</p>
        <p class="p2">회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
        <p class="p2">서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산</p>
        <p class="p2">서비스 및 콘텐츠 제공, 물품배송 또는 청구서 등 발송, 본인인증, 구매 및 요금 결제, 요금추심</p><br>
        
        <p class="p3">회원관리</p>
        <p class="p2">회원제 서비스 이용에 따른 본인확인, 개인식별, 불량회원의 부정이용 방지와 비인가 사용방지, 가입의사 확인, 가입 및 가입횟수 제한, 만14세 미만 아동의 개인정보 수집 시 법정대리인 동의여부 확인, 추후 법정대리인 본인확인, 분쟁조정을 위한 기록 보존, 불만처리 등 민원처리, 고지사항 전달</p><br>
        
        <p class="p3">신규서비스 개발 및 마케팅, 광고</p>
        <p class="p2">신규서비스(제품) 개발 및 특화, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속빈도 파악, 회원의 서비스 이용에 대한 통계, 이벤트 등 광고성 정보 전달</p><br>
        
        <p class="p3">수집한 개인정보의 보유 및 이용기간</p>
        <p class="p2">원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.</p>
        <p class="p2">단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.</p><br>
        
        <p class="p3">회사 내부방침에 의한 정보보유 사유</p>
        <p class="p2">회원이 탈퇴한 경우에도 회사는 원활한 서비스의 제공 및 부정한 서비스의 이용을 방지하기 위하여 아래와 같이 회원정보를 보관합니다.</p><br>
        
        <p class="p3">1. 부정/불량이용기록 (부정/불량이용자의 개인정보 포함)</p>
        <p class="p2">보존이유 : 서비스의 부정 및 불량 이용 방지 및 부정/불량이용자의 재가입 방지</p>
        <p class="p2">보존기간 : 1년</p><br>
        
        <p class="p3">관련 법령에 의한 정보보유 사유</p>
        <p class="p2">상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계 법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계 법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.</p>
        <p class="p2">이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.</p><br>
        
        <p class="p3">1. 계약 또는 청약철회 등에 관한 기록</p>
        <p class="p2">보존이유 : 전자상거래 등에서의 소비자보호에 관한 법률</p>
        <p class="p2">보존기간 : 5년</p><br>
        
        <p class="p3">2. 대금결제 및 재화 등의 공급에 관한 기록</p>
        <p class="p2">보존이유 : 전자상거래 등에서의 소비자보호에 관한 법률</p>
        <p class="p2">보존기간 : 5년</p><br>
        
        <p class="p3">3. 소비자의 불만 및 분쟁처리에 관한 기록</p>
        <p class="p2">보존이유 : 전자상거래 등에서의 소비자보호에 관한 법률</p>
        <p class="p2">보존기간 : 3년</p><br>
        
        <p class="p3">4. 로그기록</p>
        <p class="p2">보존이유 : 통신비밀보호법</p>
        <p class="p2">보존기간 : 3개월</p><br>

    </div>
    
  


</template>

<script>
export default {
  
}
</script>


<style scoped>
    .p1 {
        font-size: 10pt;
        margin-bottom: 5px;
    }

    .p2 {
        font-size: 9pt;
        margin-left: 10px;
        margin-bottom: 5px;
    }

    .p3 {
        font-size: 9pt;
        margin-left: 10px;
        font-weight: bold;
        margin-bottom: 5px;
    }

</style>

