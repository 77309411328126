<template>
  
		<div id="header" class="navbar-toggleable-md sticky shadow-after-3 clearfix">

			<!-- TOP NAV -->
			<header id="topNav">
				<div class="container" style="padding: 0px">
						<button class="btn btn-mobile" data-toggle="collapse" data-target=".nav-main-collapse">
							<i class="fa fa-bars"></i>
						</button>
          
						<!-- BUTTONS -->
						<ul class="float-right nav nav-pills nav-second-main">

							<!-- SEARCH -->
							<li class="search" v-if="false" >
								<a href="javascript:;">
									<i class="fa fa-search"></i>
								</a>
								<div class="search-box">
									<form action="page-search-result-1.html" method="get">
										<div class="input-group">
											<input type="text" name="src" placeholder="Search" class="form-control" />
											<span class="input-group-btn">
												<button class="btn btn-primary" type="submit">Search</button>
											</span>
										</div>
									</form>
								</div> 
							</li>
							<!-- /SEARCH -->

						</ul>
						<!-- /BUTTONS -->

					<!-- Logo -->
					<a class="logo pull-left" href="/">
						<img src="/images/mainhome/logo.png" alt=""  style="height:50px">
					</a>

					<div class="navbar-collapse pull-right nav-main-collapse collapse submenu-dark">
						<nav class="nav-main">

							<ul id="topMain" class="nav nav-pills nav-main">
								<li class="dropdown"><!-- HOME -->
									<a href="/">홈 </a>
								</li>
							
								<li>
									<router-link id="sidepanel_btn" to="/login" @click="collapse_close();" v-if="false" >로그인</router-link>
									<a id="sidepanel_btn" href="#" @click="pageMove('/login',false);" v-if="!logined" >로그인</a>
								</li>
								<li>
									<router-link id="sidepanel_btn" to="/members/sign_up" v-if="false" >회원가입</router-link>
									<a id="sidepanel_btn" href="#"  @click="member_signup()" v-if="!logined" >회원가입</a>
								</li>
								<li>
									<a id="sidepanel_btn" href="/admin/homepage" @click="pageMove('/admin/homepage',false)"  v-if="logined" >관리자페이지</a>
								</li>
								<li>
									<router-link id="sidepanel_btn" @click.native="logout('/',false)" to="#" v-if="logined"  >로그아웃</router-link>
								</li>
								<li>
									<a id="sidepanel_btn" href="#" @click="pageMove('/login',false);" v-if="false" >로그인</a>
								</li>
								<li>
									<a id="sidepanel_btn" href="#"  @click="member_signup()" v-if="false" >회원가입</a>
								</li>
							</ul>

						</nav>
					</div>

				</div>
			</header>
			<!-- /Top Nav -->
</div>




</template>


<script>


export default {
  methods: {
		test(){
			this.axios().get('/test',{})
			.then((result)=>{
				// console.log(result);
			})
		},
		
		collapse_close(){
			console.log("collase-close---------------------");
			// $('.nav-main-collapse').collapse('hide')
		},
		
		member_signup(){

      // this.notice({title: '회원가입 안내',msg: '현재 프로모션키가 있어야 회원가입 가능합니다. 문의: joony1017@naver.com', type: 'info', time: 6000})

			this.pageMove('/promotion_check',false)
		}

	}
}
</script>
