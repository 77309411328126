<template>
  <div>

    <sub-bar v-bind:subbar_data='subbar_data' ></sub-bar>
    <section>
        <div class="container">
            
            <div class="row">

                <!-- LOGIN -->
                <div class="col-lg-4 col-md-3" ></div>
                <div class="col-lg-4 col-md-6" >
                    <form class="nomargin sky-form boxed">
                        <header>
                          <i class="fa fa-lock"></i> 비밀번호 찾기
                        </header>
                        <div class="alert alert-primary" style="margin: 15px">
                          <label>비밀번호 변경 방법</label>
                          <ol style="margin-bottom: 0px;font-weight:bold">
                            <li>가입시 입력하신 아이디을 입력하고 전송합니다.</li>
                            <li>이메일을 확인 하시면 비밀번호를 변경할 수 있는 페이지 버튼이 있습니다.</li>
                            <li>[패스워드 변경하러 가기]링크를 클릭하시면 비밀번호를 변경하실 수 있습니다.</li>
                          </ol>
                        </div>
                       
                       <div v-if="!result"> 
                        <fieldset class="nomargin">	
                            <div class="alert alert-danger" v-if="error!=''">{{error}}</div> 
                            <div class="row margin-bottom-10">
                                <div class="col-md-12">
                                    <b >아이디</b>
                                    <label class="input margin-bottom-10" style="margin-top:10px">
                                        <i class="ico-append fa fa-envelope"></i>
                                        <input type="text" ref='userid' placeholder="userid" v-model="userid" class="form-control" >
                                        <b class="tooltip tooltip-bottom-right">아이디는 필수입니다.</b>
                                    </label>
                                    <div class="message" style="color:#CD554A"></div>

                                </div>  
                            </div>
                        </fieldset>

                        <div class="row margin-bottom-20 text-right">
                            <a href="javascript:void(0)" class="btn btn-sm btn-default btn-bordered btn-shadow-1" @click="pageMove('/login',false);" style='font-size:9pt;margin-left:30px'>로그인</a>
                           
                            <div class="col-md-12" style="padding-right: 40px;margin-bottom: 30px">
                                <div  class="btn btn-primary" @click="password_email()" ><i class="fa fa-check"></i> 비밀번호변경 이메일 전송</div>
                            </div>
                        </div>
                      </div>

                      <div v-else>
                        <fieldset class="nomargin">	
                          <div class="alert alert-danger" v-if="error!=''">{{error}}</div> 
                          <h3>이메일 확인 하시면 비밀번호 변경 페이지 이동 버튼이 있습니다. 클릭하시면 변경 가능한 페이지로 이동 됩니다.</h3>
                            <a href="javascript:void(0)" class="btn btn-sm btn-default btn-bordered btn-shadow-1" @click="pageMove('/',false);" style='font-size:9pt;'>메인페이지</a>
                            <a href="javascript:void(0)" class="btn btn-sm btn-default btn-bordered btn-shadow-1" @click="pageMove('/login',false);" style='font-size:9pt;'>로그인페이지</a>

                        </fieldset>


                      </div>  


                    </form>
                    <!-- /register form -->

                </div>
                <!-- /LOGIN -->

                

            </div>

            
        </div>
    </section>
  </div>
  


</template>

<script>
import SubBar from "../../common_components/subbar"
export default {
  
  data(){
    return {
      userid: '',
      email: '',
      error: '',
      subbar_data: {
        title: 'PASSWORD RESET',
        breadcrumb: ['HOME','Passwod reset']
      },
      button_use: true,
      result: false,
    }
  },
    
  created(){
    if(this.$route.query.result=='success')this.result=true
  },

  methods: {
    password_email(){
      if(this.button_use){
        this.button_use = false
        this.axios().post('/password', { userid: this.userid,  email: this.email })
        .then(result=>{
          try{
            this.notice(result.data)
            this.error = result.data.msg
            if(result.data.result=='success'){
              this.result = true
              this.$router.push('/password/new?result=success')
            }else{
              this.button_use = true
              let self = this
              setTimeout(function(){
                self.error = ''
              },5000)
            }

          }catch(e){
            console.log("error===================");
            this.button_use = true
            this.error = "문제가 발생하여 이메일을 전송하지 못했습니다. 새로고침후 다시 시도해 주세요"
            this.webpageNotice({type: 'error', msg: '페이지가 너무 오래 되었거나 토큰이 유효하지 않다. 자동으로 새로고침 됩니다.'})
            setTimeout(function(){
              location.reload()
            },3000)
          }
        });
      }
    }
  },
  
  components: {
    SubBar
  }, 
  
}
</script>