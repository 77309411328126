<template>
    <!-- wrapper -->
	<div id="wrapper">
    
		<Slide></Slide>
  </div>
    
</template>


<script>
import Slide from './components/slide'

export default {
  data: function () {
    return {
      
    }
	},
	created(){
		
	},
	methods: {
     
	},
	components: {
		Slide
	}
}
</script>


<style>

</style>
