<template>
  <div>

    <sub-bar v-bind:subbar_data='subbar_data' ></sub-bar>
    <section>
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-3" ></div>

          <div class="col-lg-4 col-md-6" >
            <form class="nomargin sky-form boxed">

              <header>
                <i class="fa fa-lock"></i> 비밀번호 변경
              </header>
              <div class="alert alert-primary" style="margin: 15px">
                <ul style="margin-bottom: 0px;font-weight:bold">
                  <li>변경하시려는 패스워드를 입력하시면 됩니다.</li>
                </ul>
              </div>

              <fieldset class="nomargin">	
                <div class="alert alert-danger" v-if="error!=''" v-html="error" ></div> 
                <div class="row margin-bottom-10">
                  <div class="col-md-12">
                    <b >변경할 비밀번호</b>
                    <label class="input margin-bottom-10" style="margin-top:10px">
                      <i class="ico-append fa fa-envelope"></i>
                      <input type="password" ref='password' placeholder="password" v-model="password" class="form-control" >
                      <b class="tooltip tooltip-bottom-right">아이디는 필수입니다.</b>
                    </label>
                    <div class="message" style="color:#CD554A"></div>
                    <b >변경할 비밀번호 확인</b>
                    <label class="input margin-bottom-10" style="margin-top:10px">
                      <i class="ico-append fa fa-envelope"></i>
                      <input type="password" ref='password_confirmation' placeholder="password_confirmation" v-model="password_confirmation" class="form-control" >
                      <b class="tooltip tooltip-bottom-right">아이디는 필수입니다.</b>
                    </label>
                    <div class="message" style="color:#CD554A"></div>
                  </div>  
                </div>
              </fieldset>

              <div class="row margin-bottom-20 text-right">
                <a href="javascript:void(0)" class="btn btn-sm btn-default btn-bordered btn-shadow-1" @click="pageMove('/login',false);" style='font-size:9pt;margin-left:30px'>로그인</a>
                
                <div class="col-md-12" style="padding-right: 40px;margin-bottom: 30px">
                  <div  class="btn btn-primary" @click="password_email()"><i class="fa fa-check"></i> 비밀번호 변경</div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
  


</template>

<script>
import SubBar from "../../common_components/subbar"
export default {
  
  data(){
    return {
      password: '',
      password_confirmation: '',
      error: '',
      subbar_data: {
        title: 'PASSWORD RESET',
        breadcrumb: ['HOME','Passwod reset']
      },
      
    }
  },
    
  created(){

    // console.log("password_find=====================");
  },

  methods: {
    password_email(){
      this.axios().put('/password', { password: this.password,  password_confirmation: this.password_confirmation, token: this.$route.query.reset_password_token })
      .then(result=>{
        this.notice(result.data)
        if(result.data.type=='success'){
            setTimeout(function(){ location.href='/login' },2000)
        }else{
          if(result.data.type=="redirect"){
            location.href="/login"
          }else{
            let err_msg = '<ul style="font-weight:bold;margin-bottom:0px">' 
            for( let e of result.data.msg ){
              err_msg +=`<li>${e}</li>`
            }
            err_msg +='</ul>'
            this.error=err_msg
            result.data.msg = err_msg
            let self = this
            setTimeout(function(){ self.error='' },4000)
          }
        }
      });
    }
  },
  
  components: {
    SubBar
  }, 
  
}
</script>