<template>
	<div>
		<Menubar></Menubar>
		<router-view ></router-view>
	  <info-var></info-var>
	</div>
</template>


<script>
import Menubar from './pages/components/menu'
import InfoVar from './pages/components/infoVar'
import Signuppage from './pages/signuppage'
import PasswordFind from './pages/password_find'


export default {
	components: {
		Menubar,
		InfoVar,
		Signuppage,
		PasswordFind
	}, 
	created(){
		console.log("mainpage================");
		this.$store.state.login = logincheck
		this.authCheck();
	},	
	computed: {
		mainComponent(){
			// return this.$route.name;
		}
	}
 
}
</script>


<style>

</style>

