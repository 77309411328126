<template>
    <!-- wrapper -->
    <div id="wrapper">
        <sub-bar v-bind:subbar_data='subbar_data'></sub-bar>
        <section>
            <div class="container">
                
                <div class="row">

                    <!-- LOGIN -->
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                        <form class="nomargin sky-form boxed">
                            <header>
                                <i class="fa fa-users"></i> 회원가입 {{ promotionName }}
                            </header>

                            <fieldset class="nomargin">	
                                <div class="alert alert-danger" v-if="error!=''">
                                    <ul>
                                        <li v-for="e in error" :key='e[0]'>{{ e[0] }}</li>
                                    </ul>
                                </div>
                                <div class="row margin-bottom-10">
                                    <div class="col-md-6">
                                        <b>아이디</b>
                                        <label class="input margin-bottom-10">
                                            <i class="ico-append fa fa-user"></i>
                                            <input type="text" placeholder="UserID" v-model="member.userid" @blur="duplicate_check('userid')" :class="{error: validation.hasError('member.userid')}" >
                                            <b class="tooltip tooltip-bottom-right">아이디는 필수입니다.</b>
                                        </label>
                                        <div class="message" style="color:#CD554A">{{ validation.firstError('member.userid') }}</div>
                                        <font id='dupleError' :color="userid_duple_check_result == 1 ? 'blue' : '#CD554A' " v-if="userid_duple_checking" >
                                            <b v-html="userid_duple_check_msg"></b><span v-if="!userid_duple_check_result" @click="duplicate_check('userid')" style="cursor:pointer">[중복확인]</span>
                                        </font>
                                    </div>    
                                    <div class="col-md-6">
                                        <b>이메일</b>
                                        <label class="input margin-bottom-10" >
                                            <i class="ico-append fa fa-envelope"></i>
                                            <input type="text"  placeholder="Email address" v-model="member.email" @blur="duplicate_check('email')" :class="{error: validation.hasError('member.email')}">
                                            <b class="tooltip tooltip-bottom-right">이메일은 필수입니다.</b>
                                        </label>
                                        <div class="message" style="color:#CD554A">{{ validation.firstError('member.email') }}</div>
                                        <font id='dupleError' :color="email_duple_check_result == 1 ? 'blue' : '#CD554A' " v-if="email_duple_checking" >
                                            <b v-html="email_duple_check_msg"></b><span v-if="!email_duple_check_result" @click="duplicate_check('email')" style="cursor:pointer">[중복확인]</span>
                                        </font>
                                    </div>    
                                </div>
                            
                                <div class="row margin-bottom-10">
                                    <div class="col-sm-6">
                                        <b>비밀번호</b>
                                        <label class="input margin-bottom-10">
                                            <i class="ico-append fa fa-lock"></i>
                                            <input type="password" placeholder="Password" v-model="member.password" :class="{error: validation.hasError('member.password')}">
                                            <b class="tooltip tooltip-bottom-right">알파벳, 특수기호, 숫자조합으로 8자 이상입니다.</b>
                                        </label>
                                        <div class="message" style="color:#CD554A">{{ validation.firstError('member.password') }}</div>

                                    </div>    

                                    <div class="col-sm-6">
                                        <b>비밀번호확인</b>
                                        <label class="input margin-bottom-10">
                                            <i class="ico-append fa fa-lock"></i>
                                            <input type="password" placeholder="Confirm password" v-model="member.password_confirmation" :class="{error: validation.hasError('member.password_confirmation')}">
                                            <b class="tooltip tooltip-bottom-right">비밀번호를 확인합니다.</b>
                                        </label>
                                        <div class="message" style="color:#CD554A">{{ validation.firstError('member.password_confirmation') }}</div>

                                    </div>
                                </div>    

                                <div class="row margin-bottom-10">
                                    <div class="col-md-6">
                                        <b>성함</b>
                                        <label class="input">
                                            <input type="text" placeholder="Name" v-model="member.username" :class="{error: validation.hasError('member.username')}" >
                                            <b class="tooltip tooltip-bottom-right">성함은 필수입니다.</b>
                                        </label>
                                        <div class="message" style="color:#CD554A">{{ validation.firstError('member.username') }}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <b>전화번호</b>
                                        <label class="input">
                                            <input type="text" placeholder="Phone" v-model="member.userphone">
                                        </label>
                                    </div>
                                </div>
                                <div class="row margin-bottom-10">
                                    <div class="col-sm-6">
                                        <label style="margin-bottom: 0px">국가</label>
                                        <el-select v-model="member.nation" placeholder="Select" >
                                            <el-option
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div class="col-sm-6">
                                        <b>지역(도시/군)</b>
                                        <label class="input margin-bottom-10">
                                            <i class="ico-append fa fa-lock"></i>
                                            <input type="text" placeholder="Location" v-model="member.location">
                                            <b class="tooltip tooltip-bottom-right">살고 계신 지역명 시 또는 지역을 입력해 주세요</b>
                                        </label>
                                    </div>
                                </div>        

                                <div class="margin-top-30">
                                    <label class="checkbox nomargin">
                                        <input :class="{error: validation.hasError('accept1')}" v-model='accept1' type="checkbox" name="checkbox">
                                        <i></i> 
                                        <a href="#" data-toggle="modal" data-target="#termsModal" @click="modal_component='p-info-law'" >개인정보보호 약관</a>에 동의 합니다.
                                    </label>
                                    <label class="checkbox nomargin"><input :class="{error: validation.hasError('accept2')}" v-model='accept2' type="checkbox" name="checkbox"><i></i> <a href="#" data-toggle="modal" data-target="#termsModal" @click="modal_component='member-law'">회원 이용약관</a>에 동의 합니다.</label>
                                </div>
                                <div class="message" style="color:#CD554A;font-weight: bold">{{ validation.firstError('accept1') }}</div>
                                <div class="message" style="color:#CD554A;font-weight: bold">{{ validation.firstError('accept2') }}</div>

                            </fieldset>

                            <a href="javascript:void(0)" class="btn btn-sm btn-default btn-bordered btn-shadow-1" @click="pageMove('/login',false);" style='font-size:9pt;'>로그인</a>
                            <div class="row margin-bottom-20 text-right">
                                <div class="col-md-12" style="padding-right: 40px;margin-bottom:30px">
                                    <div  class="btn btn-primary" @click="memberCreate()"><i class="fa fa-check"></i> 회원가입</div>
                                </div>
                            </div>

                        </form>
                        <!-- /register form -->

                    </div>
                    <!-- /LOGIN -->

                </div>
            </div>
        </section>
        <!-- / -->

        <!-- MODAL -->
        <div class="modal fade" id="termsModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                            &times;
                        </button>
                        <h4 class="modal-title" style="width:80%" id="myModal"></h4>
                    </div>
              
                    <component :is="modal_component" ></component>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal"> 취소</button>
                        <button type="button" class="btn btn-primary" id="terms-agree" @click="agree()" ><i class="fa fa-check"></i> 동의합니다.</button>
                    </div>

                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /MODAL -->

    </div>
    
</template>


<script>
import SubBar from "../../common_components/subbar"
import Vue from 'vue'
import SimpleVueValidation from 'simple-vue-validator';
import PInfoLaw from "./components/p_info_law"
import MemberLaw from "./components/member_law"

const Validator = SimpleVueValidation.Validator.create({
    templates: {
        accept1: '개인정보보호 약관에 동의하셔야 가입이 가능합니다.'
    }
});
Vue.use(SimpleVueValidation);

export default {
   data(){
       return {
           options: [],
           submited: false,
           member: { 
               userid: '',
               email: '',
               username: '',
               password: '',
               password_confirmation: '',
               nation : 'KOR',
               location: ''
           },
           valid_check: {userid:['',0], email:['',0]},
           accept1: [],
           accept2: [],
           error: '',
           userid_duple_check_msg:'',
           userid_duple_checking: false,
           email_duple_check_msg:'',
           email_duple_checking: false,
           modal_component: '',
           subbar_data: {
                title: 'REGISTER',
                breadcrumb: ['HOME','Register']
            },
            promotion_name: ''
       }
    },
    validators: {
        'member.email': function(value) {
            return this.email_validate(value)
        },

        'member.userid' : function (value) {
            return this.userid_validate(value)
        },

        'member.username' : function (value) {
            return Validator.value(value).required("필수 항목입니다.(required)");
        },
       
        'member.password' : function (value) {
            return Validator.value(value).required("필수 항목입니다.(required)").regex('^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-_])(?=.*[0-9]).{8,32}', '알파벳+숫자+특수기호를 혼합하여 8자이상 사용하셔야 합니다.(alphabet + number + special characters and More than 8 characters)');
        },

        'member.password_confirmation, member.password' : function (confirmation, password) {
            if (this.submited || this.validation.isTouched('member.password_confirmation')) return Validator.value(confirmation).required("필수 항목입니다.(required)").match(password);
        },

        accept1: function(value){
            return  Validator.value(value).required('개인정보보호 약관에 동의 하셔야 가입이 가능합니다. ')
        },
       
        accept2: function(value){
            return  Validator.value(value).required('회원이용 약관에 동의 하셔야 가입이 가능합니다. ')
        }
       
    },
    created(){
        const pkey = this.$route.query.pkey!=undefined ? `?pkey=${this.$route.query.pkey}` : ''    
        this.$http.get(`/get_nations.json${pkey}`,{test: 'data'})
        .then((result)=>{
            this.options = result.data.result
            this.promotion_name = result.data.promotion_name
        }) 
    },
    components: {
        SubBar,
        PInfoLaw,
        MemberLaw
    }, 
    
    computed: {
        promotionName(){
            return this.promotion_name!='' ? `[${this.promotion_name}]` : ''
        } 
    },

    methods:{
        memberCreate(){
            this.submited =  true
                this.$validate()
                .then((result)=>{
                    // this.$http.defaults.headers.common["authToken"] = this.$store.state.authToken
                    if(result){
                        if(this.userid_duple_check_result  && this.email_duple_check_result){
                            let data = { member: this.member } 
                            if(this.$route.query.pkey!=undefined)data['pkey']=this.$route.query.pkey
                            this.$http.post('/members',  data )
                            .then((result)=>{
                                if(result.data.result){
                                    localStorage.setItem('token', result.data.token)
                                    this.$store.commit('set_login',result)
                                    this.notice({title: '로그인',msg: '성공적으로 회원가입 되었습니다./n/r자동으로 로그인 됩니다.', type: 'success', loc:'/',loctype: 'a',time: 1000})
                                } else {
                                this.notice({title: '로그인',msg: result.data.info, type: 'error'})
                                this.error = result.data.info
                                }
                            })
                        }else{
                            this.error = [['입력이 완료 되지 않았습니다. 확인하시고 입력을 완료해 주세요!']]
                        }
                    }
                })
        },
        
        duplicate_check(type){
           
            if(this.type_check(type)){
                let validate = true    
                if(type=='userid'){
                    const userid_check = this.userid_validate(this.member.userid)._messages.length;    
                    var check_id =`userid=${this.member.userid}` 
                    validate = userid_check == 0
                }else{
                    const email_check = this.email_validate(this.member.email)._messages.length;    
                    var check_id =`email=${this.member.email}`
                    validate = email_check == 0
                }
                if(validate){
                    this.$http.get(`/attr_duple_check?${check_id}&type=${type}`)
                    .then((result)=>{
                        this.valid_check[type][0] = this.member[type]
                        this.valid_check[type][1] += 1
                        this[`${type}_duple_checking`] = true
                        this.result_excute(type,result)
                    })
                }

            }
        },

        result_excute(type,result){
            this[`${type}_duple_check_result`] = result.data.msg[type][1]
            this[`${type}_duple_check_msg`] = result.data.msg[type][0]
        },

        type_check(type){
            if(this.valid_check[type][0]!=this.member[type]){
                this.valid_check[type][1] = 0
                return true
            }else{
                if(this.valid_check[type][1] < 4 ) return true
            }
            return false
        },

        agree(){
            if(this.modal_component=='p-info-law'){
                this.accept1 = true
            }else{
                this.accept2 = true
            }
            $('.modal').modal('hide')
        },
        
        userid_validate(value){
            return Validator.value(value).required("필수 항목입니다.").regex('^[a-z]+[a-z0-9]{5,32}$', '알파벳이나 숫자를 혼합하여 6자이상 사용합니다.(alphabet + number and More then 6 characters)');
        },

        email_validate(value){
            return Validator.value(value).required("필수 항목입니다.").email();
        }

    }
  
}
</script>


<style>


</style>

