import Vue from 'vue'
import Vuex from 'vuex'
import Es6Promise from 'es6-promise';
import axios from 'axios'
Es6Promise.polyfill();

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        login: false,
        user: {},
    },

    mutations: {
        set_login(state, result){
            console.log("store==================");
            // console.log(result);
            state.login = (result.headers.admin_logined == 'true') ? true : false
        }
    },

    actions: {
        promotion_code_confirm({commit}, code){
            return axios.get(`/promotion_confirm/${code}`)
            .then(result=>{
                return result
            })
        }


    }

});