<template>
   <div>


		
			<!-- REVOLUTION SLIDER -->
			<div class="slider fullwidthbanner-container roundedcorners">
				<!--
					Navigation Styles:
					
						data-navigationStyle="" theme default navigation
						
						data-navigationStyle="preview1"
						data-navigationStyle="preview2"
						data-navigationStyle="preview3"
						data-navigationStyle="preview4"
						
					Bottom Shadows
						data-shadow="1"
						data-shadow="2"
						data-shadow="3"
						
					Slider Height (do not use on fullscreen mode)
						data-height="300"
						data-height="350"
						data-height="400"
						data-height="450"
						data-height="500"
						data-height="550"
						data-height="600"
						data-height="650"
						data-height="700"
						data-height="750"
						data-height="800"
				-->
				<div class="fullwidthbanner" data-height="600" data-shadow="0" data-navigationStyle="preview2">
					<ul class="hide">
						<component :is="s" v-for="(s, index) in slides" :key="index" ></component>
					</ul>

					<div class="tp-bannertimer"><!-- progress bar --></div>
				</div>
			</div>
			<!-- /REVOLUTION SLIDER -->

	 </div>

</template>


<script>
import Slide01 from './slide/slide01'
import Slide02 from './slide/slide02'
import Slide03 from './slide/slide03'
import Slide04 from './slide/slide04'
import Slide05 from './slide/slide05'
import Slide06 from './slide/slide06'

export default {
	data(){
		return {
			slides: ['slide04','slide01', 'slide03','slide02']
			// slides: ['slide04','slide01','slide02','slide03','slide05','slide06']
		}
	},
	
	components: {
		Slide01,
		Slide02,
		Slide03,
		Slide04,
		Slide05,
		Slide06
	}
  
}
</script>
