<template>
<!-- SLIDE  -->
  <li data-transition="random" data-slotamount="1" data-masterspeed="1000" data-saveperformance="off" data-title="Slide 2">

    <img src="/smart_webpage/assets/images/_smarty/1x1.png" data-lazyload="/images/mainhome/slide04-01.png" alt="" data-bgfit="cover" data-bgposition="center bottom" data-bgrepeat="no-repeat" />

    
    <div class="tp-caption lfr" 
      data-x="left" data-hoffset="60"
      data-y="85" 
      data-speed="1500" 
      data-start="500" 
      data-easing="easeOutExpo" 
      data-elementdelay="0.1" 
      data-endelementdelay="0.1" 
      data-endspeed="300" 
      style="z-index: 2;">
      <img src="/smart_webpage/assets/images/_smarty/1x1.png" data-lazyload="/images/mainhome/slide04-02.png" alt="" />
    </div>

    <div class="tp-caption customin ltl block_black sft"
      data-x="center"
      data-y="155"
      data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
      data-speed="800"
      data-start="1000"
      data-easing="easeOutQuad"
      data-splitin="none"
      data-splitout="none"
      data-elementdelay="0.01"
      data-endelementdelay="0.1"
      data-endspeed="1000"
      data-endeasing="Power4.easeIn" style="z-index: 10;">
      서브페이지생성 / 게시판생성 / 보안페이지 / 회원가입기능 / 포탈형 샤플 / 뭉글 
    </div>

    <div class="tp-caption customin ltl tp-resizeme large_bold_white"
      data-x="center"
      data-y="205"
      data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
      data-speed="800"
      data-start="1200"
      data-easing="easeOutQuad"
      data-splitin="none"
      data-splitout="none"
      data-elementdelay="0.01"
      data-endelementdelay="0.1"
      data-endspeed="1000"
      data-endeasing="Power4.easeIn" style="z-index: 10;color:#EB1B51">
      다양한 서브페이지 만들기
    </div>

    <div class="tp-caption customin ltl tp-resizeme small_light_white font-lato"
      data-x="center"
      data-y="295"
      data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
      data-speed="800"
      data-start="1400"
      data-easing="easeOutQuad"
      data-splitin="none"
      data-splitout="none"
      data-elementdelay="0.01"
      data-endelementdelay="0.1"
      data-endspeed="1000"
      data-endeasing="Power4.easeIn" style="z-index: 10; width: 100%; max-width: 750px; white-space: normal; text-align:center; font-size:1.5rem;font-weight:bold">
      샤플을 사용하지 않아도 데이터를 보관합니다. 언제든지 끄고, 켤수 있습니다.<br>
      비밀번호를 샤플에 걸어서 보안페이지로 사용할 수 있습니다.<br>
      샤플을 모아 포탈형 샤플을 만들 수 있습니다.<br>
    </div>

    <div class="tp-caption customin ltl tp-resizeme"
      data-x="center"
      data-y="433"
      data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
      data-speed="800"
      data-start="1550"
      data-easing="easeOutQuad"
      data-splitin="none"
      data-splitout="none"
      data-elementdelay="0.01"
      data-endelementdelay="0.1"
      data-endspeed="1000"
      data-endeasing="Power4.easeIn" style="z-index: 10;">
      <a href="#purchase" class="btn btn-default btn-lg">
        <span> <a :href="page_go" style="color:white">경험해 보세요!</a> </span> 
      </a>
    </div>

  </li>

</template>

<script>
export default {
  
 computed: {
   page_go(){
     if(this.$store.state.login){
       return '/admin/homepage'
     }else{
       return '/members/sign_up'
     }
   }
 }

}
</script>